import React, { useEffect } from "react";

import PageTitle, { type PageTitleProps } from "components/common/other/PageTitle/PageTitle";
import Layout from "components/layout/Layout/Layout";
import {
  NotFoundDescription,
  NotFoundLink,
  NotFoundWrapper
} from "components/pages/404/NotFound.styled";
import MainCloud from "components/rendering/MainCloud/MainCloud";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import type { PageProps } from "pages/index";
import { InvokeAnalyticsEvent } from "providers/AnalyticsServiceProvider";
import { GetSeoData } from "utils/seo-utils";
import Translate from "utils/translation/translation";
import { isWindowUndefined } from "utils/window-utils";

const Contact = ({ location }: PageProps) => {
  useEffect(() => {
    if (isWindowUndefined()) {
      return;
    }

    const originalLocation = new URLSearchParams(
      location.search && location.search !== "" && location.search !== null
        ? location.search
        : "?original=/404"
    ).get("original");

    InvokeAnalyticsEvent(
      "error",
      location,
      "errors",
      "invalid-url",
      originalLocation!,
      undefined,
      true
    );
  }, [location]);

  const generateDescription = () => {
    const data: string = Translate("Not_Found_Description");
    const wordToFind = "Play again";
    const linkIndex = data.indexOf("Play again");
    const dataFirstPortion = data.slice(0, linkIndex);
    const dataSecondPortion = data.slice(linkIndex + wordToFind.length);
    const linkData = data.slice(linkIndex, linkIndex + wordToFind.length);

    return (
      <NotFoundDescription>
        {dataFirstPortion}
        <NotFoundLink to="/">{linkData}</NotFoundLink>
        {dataSecondPortion}
      </NotFoundDescription>
    );
  };

  return (
    <>
      <GatsbySeo {...GetSeoData("404")} />
      <Layout location={location}>
        <NotFoundWrapper>
          <MainCloud color="VioletBlack" variant="MainCloudWrapper" scrollSpeed={-7.5} />
          <PageTitle {...pageTitleData} />
          {generateDescription()}
        </NotFoundWrapper>
      </Layout>
    </>
  );
};

export default Contact;

const pageTitleData: PageTitleProps = {
  instantPlayback: true,
  variant: "not-found-title",
  isTitle: true,
  headers: [
    {
      text: "Not_Found_Title_404",
      variant: "NotFoundTitle404",
      id: "not-found-title-404",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0 },
      scrollSpeed: 1
    },
    {
      text: "Not_Found_Title_Not",
      variant: "NotFoundTitleNot",
      id: "not-found-title-not",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0 },
      scrollSpeed: 1
    },
    {
      text: "Not_Found_Title_Found",
      variant: "NotFoundTitleFound",
      id: "not-found-title-found",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0 },
      scrollSpeed: 1
    }
  ]
};
