import { Link } from "gatsby";
import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { CommonTitleDescriptionFont } from "styled/common/font-styles/CommonFonts";
import styled from "styled-components";

export const NotFoundWrapper = styled.section`
  height: 100vh;
  ${CalculateFromHeight("margin-top", { mobile: 15, tablet: 7 })}
`;

export const NotFoundDescription = styled.h2`
  ${CommonTitleDescriptionFont}
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    width: 80%;
  }
  ${CalculateFromWidth("margin-left", { mobile: 10, tablet: 7, desktop: 1 })}
  ${CalculateFromWidth("margin-top", { tablet: 3 })};
`;

export const NotFoundLink = styled(Link)`
  ${({ theme: { fonts } }) => fonts.medium};
  color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.white};
`;
